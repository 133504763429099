import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout/Layout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import ScreenCard from "../../components/cards/ScreenCard"

import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Navigation } from "swiper";
// Import Swiper styles
import 'swiper/css';
import "swiper/css/navigation";

import { Router } from "@reach/router"
import { getUser, isLoggedIn } from "../../services/auth"
import LoginPrompt from "../../components/app/loginPrompt"


export default function Pages( {data}) {

return (
  <Layout>
  <Container>
  {isLoggedIn() ? (
  <>
  <div className="flex flex-col lg:flex-row-reverse ">
 <div className="w-full lg:w-1/3">
   {data.airtable.data.JourneyGroups.map((array) => (
     <a href={array.data.Path}><Type type="p4" nopadding="yes">{array.data.GroupName}</Type></a>
   ))}
   {data.airtable.data.Organisations.map((array) => (
    <a href={array.data.Path}><Type type="p5">{array.data.Name}</Type></a>
    ))}
    <Type type="h2" nopadding="yes">{data.airtable.data.OrganisationTxt} {data.airtable.data.Title}</Type>
  </div>
      
<div className="w-full lg:w-2/3 lg:mt-0 mt-12 lg:pr-36 ">
    <Swiper
  // install Swiper modules
  style={{
    "--swiper-navigation-color": "#000",
    "--swiper-pagination-color": "#000",
  }}
  modules={[Navigation]}
  slidesPerView={3}
  spaceBetween={30}
  loop={false}
  navigation
  onSwiper={(swiper) => console.log(swiper)}
  onSlideChange={() => console.log('slide change')}
>
         
            {data.airtable.data.Screens.map((array) => (

              <SwiperSlide> 
                <ScreenCard title={array.data.Name} image={array.data.ThumbS3} url={array.data.Path} />
              </SwiperSlide>
              ))}
          </Swiper>
  
   
  </div>
  </div>
    
     </>
   ) : (
     <>
       <LoginPrompt/>
     </>
   )}

  </Container>
  </Layout>
  )
}


export const query = graphql`
  query GetPage($Path: String!) {
    airtable(table: { eq: "Journeys" },
    data: { Path: { eq: $Path } }) {
      data {
        JourneyName
        Title
        OrganisationTxt
        Organisations {
          data {
            Name
            Path
          }
        }
        JourneyGroups {
          data {
            GroupName
            Path
          }
        }
        Screens {
          data {
            Name
            Path
            ThumbS3
            
          }
        }
      }
    }
  }
`
